import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Modal,
  Paper,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import GoogleMapComponent from '../../components/location/google-map';
import OverlayComponent from './overlayComponent';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import crossIcon from '../../assets/imgs/ac.svg';
import { ClosedCaption } from '@mui/icons-material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BackArrowRounded from '../../assets/imgs/back-arrow-rounded-icon.svg';
import CancelRoudedIcon from '../../assets/imgs/cancel-icon.svg';
import { updateUser } from '../../redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { ResponseRestaurant } from '../../types/olo-api/ResponseRestaurant';
import { getSingleLocation } from '../../redux/actions/location';

const MapDialog = ({
  zoom,
  mapCenter,
  markers,
  fitMapView,
  setActionPerform,
  setIsMapLoaded,
  action,
  actionTypes,
  currentLocation,
  markerRef,
  filteredRestaurants,
  onClose,
  open,
  searchText,
  setSearchText,
  getSearchResults,
  setOpenDialog,
  setFavLocation,
  setFavLocationError,
  newFavLocation,
  onFocusFavouriteLocation,
}: any) => {
  const [selectedResturant, setSelectedResturant] = useState<any>();
  const [selectedNewFavResturant, setSelectedNewFavResturant] = useState({
    extref: 'someValue',
  });
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { singleLocation } = useSelector((state: any) => state.locationReducer);
  const { restaurant } = useSelector(
    (state: any) => state.restaurantInfoReducer,
  );

  const [restaurantInfo, setRestaurantInfo] = useState<ResponseRestaurant>();
  useEffect(() => {
    if (open === true) setOpenBottomSheet(true);
  }, []);

  const handleChangeSearchText = (e: any) => {
    setSearchText(e);
  };
  const setSelectedData = (dataItem: any) => {
    setSelectedResturant(dataItem);
  };

  useEffect(() => {
    if (newFavLocation && selectedResturant) {
      setRestaurantInfo(selectedResturant);
      if (selectedResturant?.extref) {
        dispatch(getSingleLocation(selectedResturant?.extref));
      }
    }
  }, [selectedResturant, restaurant]);
  useEffect(() => {
    if (
      newFavLocation &&
      singleLocation?.data?.length &&
      selectedNewFavResturant
    ) {
      if (selectedResturant) {
        setSelectedNewFavResturant(selectedResturant);
      }

      const matchingLocations = singleLocation?.data?.filter(
        (da: any) => selectedResturant?.extref === da?.store_number,
      );

      if (matchingLocations?.length > 0) {
        setLocationId(matchingLocations[0]?.location_id);
      }
    }
  }, [singleLocation, selectedNewFavResturant, restaurant, selectedResturant]);

  const onSaveDaliog = (dataItem: any) => {
    if (newFavLocation) {
      if (locationId) {
        const obj = {
          favourite_location_ids: locationId,
        };
        dispatch(updateUser(obj, false));
        setOpenDialog(false);
      }
    } else {
      setFavLocation(selectedResturant);
      setFavLocationError(false);
      setOpenDialog(false);
    }
  };
  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box className="dailog-parent-container">
      {(!isMobile && (
        <Dialog
          aria-hidden={open ? 'false' : 'true'}
          // aria-label="map"
          role=""
          tabIndex={0}
          open={open}
          onClose={onClose}
          PaperProps={{
            'aria-modal': 'true',
            style: {
              width: '85vw',
              maxWidth: '100%',
              margin: 'auto',
              height: '85vh',
              borderRadius: '16px',
              overflowX: 'hidden',
            },
          }}
          aria-labelledby="select-restaurant"
          TransitionProps={{
            role: '',
            // 'aria-modal': 'true',
            // 'aria-label': 'Add Gift Card',
          }}
          aria-describedby="restaurant-dialog"
          onKeyDown={(e) => {
            if (e.key === 'Escape' && onFocusFavouriteLocation) {
              onFocusFavouriteLocation();
            }
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: '1',
              paddingRight: '25px',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onFocusFavouriteLocation) {
                onFocusFavouriteLocation();
              }
            }}
          >
            <p className="sr-only" id="restaurant-dialog">
              Activating these elements will cause content on the page to be
              updated.
            </p>
            <Box
              component="img"
              src={CancelRoudedIcon}
              sx={{ width: '32px', height: '32px' }}
              alt=""
            />
          </IconButton>
          <DialogContent>
            <OverlayComponent
              searchText={searchText}
              getSearchResults={getSearchResults}
              handleChangeSearchText={handleChangeSearchText}
              currentLocation={currentLocation}
              filteredRestaurants={filteredRestaurants}
              onSave={onSaveDaliog}
              setSelectedResturantData={setSelectedData}
              selectedResturant={selectedResturant}
              onFocusFavouriteLocation={onFocusFavouriteLocation}
            />
            <GoogleMapComponent
              zoom={zoom}
              mapCenter={mapCenter}
              markers={markers}
              fitMapView={fitMapView}
              action={action}
              actionTypes={actionTypes}
              currentLocation={currentLocation}
              markerRef={markerRef}
              filteredRestaurants={filteredRestaurants}
              heightType={true}
            />
          </DialogContent>
        </Dialog>
      )) || (
        <Box>
          {open && (
            <div style={{ height: 'auto', backgroundColor: 'red' }}>
              <GoogleMapComponent
                zoom={zoom}
                mapCenter={mapCenter}
                markers={markers}
                fitMapView={fitMapView}
                action={action}
                actionTypes={actionTypes}
                currentLocation={currentLocation}
                markerRef={markerRef}
                filteredRestaurants={filteredRestaurants}
              />
            </div>
          )}

          <BottomSheet
            open={openBottomSheet}
            expandOnContentDrag={true}
            defaultSnap={500}
            snapPoints={({ minHeight }) => [100, 300, 500, 600]}
            scrollLocking={false}
            blocking={false}
            style={{ maxHeight: '90vh' }}
          >
            <Box sx={{ display: 'flex' }}>
              <div style={{ height: 500, top: '2px' }}>
                <OverlayComponent
                  searchText={searchText}
                  getSearchResults={getSearchResults}
                  handleChangeSearchText={handleChangeSearchText}
                  currentLocation={currentLocation}
                  filteredRestaurants={filteredRestaurants}
                  onSave={onSaveDaliog}
                  setSelectedResturantData={setSelectedData}
                  selectedResturant={selectedResturant}
                  isMobile={isMobile}
                  onClose={onCloseDialog}
                />
              </div>
            </Box>
          </BottomSheet>
        </Box>
      )}
    </Box>
  );
};

export default MapDialog;
