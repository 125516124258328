import AppRoutes from './routes';

function App(props: any) {
  return (
    <div id="wapper">
      <section>
        <AppRoutes />
      </section>
    </div>
  );
}
export default App;
