import { forwardRef, useEffect, useState } from 'react';
import { Grid, useTheme, Typography, useMediaQuery, Dialog, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import crossIcon from '../../../assets/imgs/ac.svg';
import './index.css';
import { Formik } from 'formik';
import { IMaskInput } from 'react-imask';

import { createCheckIn } from '../../../redux/actions/check-in';
import { useStateContext } from '../stateContext';

const ManualEditDialog = (props: any) => {
  const {
    openManualDialog,
    setOpenManualDialog,
    setOpenDrawer
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [disableSubmit, setDisableSubmit] = useState<any>(false);
  const { updateSharedState } = useStateContext();
  const isMobile = useMediaQuery(theme.breakpoints.down(480));
  const { data, error } = useSelector((state: any) => state.checkInReducer);
  useEffect(() => {
    if (data.checkin_id) {
      updateSharedState({ type: 'UPDATE_SHARED_STATE', payload: true });
      setTimeout(() => {
        setOpenDrawer(false)
      }, 100);
      handleClose()
    }

    if (error.code) {
      setDisableSubmit(false)
    }
  }, [data, error]);

  const handleClose = () => {
    setOpenManualDialog(false)
  };
  const backdropClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    handleClose();
  };

  const submitBarcode = async (barcode: any) => {
    setDisableSubmit(true)
    dispatch(createCheckIn(barcode.replace(/\D/g, '')));
  };
  interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  const NumberFormatCustom = forwardRef<HTMLElement, CustomProps>(
    function NumberFormatCustom(props: any, ref) {
      const { onChange, ...other } = props;

      return (
        <IMaskInput
          {...other}
          mask="00-000-000-0000-0"
          onAccept={(value: any) =>
            onChange({ target: { name: props.name, value } })
          }
          overwrite
        />
      );
    },
  );
  return (
    <>
      <Dialog open={openManualDialog}
        fullScreen={isMobile}
        onClose={backdropClose} sx={{
          marginTop: { xs: '410px', sm: '0px' },
          overflowY: 'hidden',
          borderRadius: '16px 16px 0px 0px',
        }} className="phone-border">
        <Grid sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'fixed',
          background: '#F6F6F6',
          width: '100%',
          zIndex: '5555',
          borderRadius: '16px 16px 0px 0px',
        }}>
          <Grid item xs={12} sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '20px', alignItems: 'center'
          }}>
            <Typography
              title="CHANGE PASSWORD"
              sx={{ fontSize: '20px', fontFamily: "'GritSans-Bold' !important", marginTop: '10px', }}
            >
              ENTER CODE
            </Typography>
            <Grid sx={{
              backgroundColor: "#FFFFFFC2", height: '32px', width: '32px', boxShadow: '0px 0px 13px 0px #A1A1A14D',
              borderRadius: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
              position: 'absolute',
              right: '16px'
            }}>
              <img style={{ width: '14px', height: '14px', display: 'flex' }} src={crossIcon} onClick={() => { setOpenManualDialog(false) }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ margin: '80px 15px 10px 15px' }}>
          <Grid>
            <Typography sx={{
              fontFamily: "'Librefranklin-Regular' !important",
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '18px',
              letterSpacing: '0.15px',
              textAlign: 'left',
              color: '#052C4280',
              marginBottom: '24px',
            }}>
              Enter the numeric code at the bottom of your receipt to earn points!
            </Typography>
          </Grid>
          <Formik
            initialValues={{
              barcode: '',
            }}
            validationSchema={Yup.object({
              phone: Yup.string().min(14, 'Enter valid number').required('Phone is required'),
            })}
            onSubmit={async (values) => { }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              isValid,
              dirty,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container className="address-form-fields-changenumber" sx={{ width: '100%', maxWidth: '450px' }}>
                  < Grid item xs={12} sx={{ paddingTop: '10px' }}>
                    <TextField
                      aria-label="Receipt Barcode"
                      label="Receipt Barcode"
                      title="Receipt Barcode2"
                      placeholder='Enter Receipt Barcode '
                      type="text"
                      name="barcode"
                      autoComplete="Receipt Barcode"
                      sx={{ width: '100%' }}
                      value={values.barcode}
                      onChange={handleChange('barcode')}
                      onBlur={handleBlur('barcode')}
                      error={Boolean(touched.barcode && errors.barcode)}
                      helperText={touched.barcode && errors.barcode}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                      }}
                      tabIndex={0}
                    />
                  </Grid>
                  <Grid sx={{ display: 'contents' }}>
                    <Button variant='contained'
                      sx={{ width: '175px', height: '42px', borderRadius: '30px !important', margin: 'auto', marginTop: '40px' }}
                      disabled={values.barcode.length < 17 || disableSubmit}
                      className="save-completed-btn" onClick={() => submitBarcode(values.barcode)}
                      tabIndex={0} role='button'
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          submitBarcode(values.barcode)
                        }
                      }}
                    >Verify</Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>

      </Dialog >
    </>
  );
};
export default ManualEditDialog;
