import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Divider,
  Grid,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from '../../components/page-title';
import './index.css';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isLoginUser } from '../../helpers/auth';
import { HoursListing } from '../../helpers/hoursListing';
import {
  getAdressFromGeocode,
  getGeoCode,
  getOrderTypeRestaurants,
  removeNumberFromRestaurantName,
} from '../../helpers/location';
import { facebookSendEvent } from '../../redux/actions/facebook-conversion';
import { getSingleLocation, getlocations } from '../../redux/actions/location';
import { getResturantCalendarRequest } from '../../redux/actions/restaurant/calendar';
import { facebookConversionTypes } from '../../redux/types/facebook-conversion';
import PickUpAddress from '../../assets/imgs/pickUpAddressIcon.png';
import { ResponseRestaurant } from '../../types/olo-api/ResponseRestaurant';
import { getUserprofile } from '../../redux/actions/user';
import {
  getNearByResturantListRequest,
  getResturantListRequest,
} from '../../redux/actions/restaurant/list';
import changeStoreIcon from '../../assets/imgs/changeRestIcon.svg';
import MapDialog from '../../components/register-form/MapDialog';
import { getLatLng } from 'use-places-autocomplete';
import { getAddress } from '../../helpers/common';
import { displayToast } from '../../helpers/toast';
import { Marker } from '@react-google-maps/api';
import markerIcon from '../../assets/imgs/markerImage.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0px 20px 40px 20px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 30px 40px 30px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 40px 0px',
    },
    maxWidth: '1100px',
    boxSizing: 'border-box',
  },
  heading: {
    paddingBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px !important',
    },
  },
  tabspanel: {
    fontFamily: 'Librefranklin-Regular',
    fontSize: '18px !important',
    fontWeight: '600  !important',
    lineHeight: '20px  !important',
    letterSpacing: '0.15px !important',
    textAlign: 'left',
    color: '#052C42',
  },
}));

const FavouriteRestaurant = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actionTypes: any = {
    CURRENT_LOCATION: 'CURRENT_LOCATION',
    LOCAL_SEARCH: 'LOCAL_SEARCH',
    GOOGLE_SEARCH: 'GOOGLE_SEARCH',
    ALL: 'ALL',
  };
  const orderType = null;
  const [showMore, setShowMore] = useState(true);
  const [zoom, setZoom] = useState<number>(7);
  const [action, setAction] = useState(actionTypes.All);
  const [filteredRestaurants, setFilteredRestaurants] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [deliveryAddressString, setDeliveryAddressString] = useState<any>();
  const [restaurantNotFound, setRestaurantNotFound] = useState(false);
  const [hideCurrentLocation, setHideCurrentLocation] = useState(false);
  const [loadDynamicMap, setLoadDynamicMap] = useState(false);
  const [searchText, setSearchText] = useState<string>();
  const markerRef: any = useRef([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newFavLocation, setNewFavLocation] = useState(true);
  const [mapCenter, setMapCenter] = useState<any>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [restaurantInfo, setRestaurantInfo] = useState<ResponseRestaurant>();
  const {
    restaurants,
    nearbyRestaurants,
    loading: restaurantLoading,
  } = useSelector((state: any) => state.restaurantListReducer);
  const { providerToken } = useSelector((state: any) => state.providerReducer);
  const {
    favDistance,
    userProfile,
    userDeliveryAddresses,
    loading: deliveryAddressesLoading,
  } = useSelector((state: any) => state.userReducer);
  const favouriteStoreNumber = userProfile?.favourite_store_numbers;
  const matchedRestaurant: any = restaurants?.find(
    (restaurant: any) => restaurant?.extref === favouriteStoreNumber,
  );
  const { calendar } = useSelector(
    (state: any) => state.restaurantCalendarReducer,
  );
  const mapRef: any = useRef();

  const populateMarkersOnMap = (restaurants: any) => {
    setMarkers([]);
    let markersStatic: any = [];
    restaurants?.forEach((item: ResponseRestaurant, index: number) => {
      if (item.id !== 64081) {
        markersStatic.push({
          color: 'red',
          markers: [{ location: { lat: item.latitude, lng: item.longitude } }],
        });
      }

      if (mapCenter === undefined) {
      }
      let latLong = {
        lat: item.latitude,
        lng: parseFloat(item.longitude),
      };
      setMarkers((markers) => [
        ...markers,
        <Marker
          key={Math.random() + index}
          position={latLong}
          icon={{
            url: markerIcon,
          }}
          onLoad={(ref: any) => (markerRef.current[index] = ref)}
        />,
      ]);
    });
  };

  useEffect(() => {
    if (restaurants) {
      if (restaurants.length === 0) {
        setFilteredRestaurants([]);
      } else {
        const rest = getOrderTypeRestaurants(restaurants, null);
        setFilteredRestaurants(rest);
        setMapCenter({
          lat: restaurants[0].latitude,
          lng: restaurants[0].longitude,
        });
        setZoom(7);
      }
    }
  }, [restaurants]);

  useEffect(() => {
    if (openDialog) {
      setMapCenter({
        lat: 37.772,
        lng: -122.214,
      });
      dispatch(getResturantListRequest());
      dispatch(getlocations());
    }
  }, []);
  useEffect(() => {
    setMapCenter({
      lat: 37.772,
      lng: -122.214,
    });
    dispatch(getlocations());
  }, []);

  useEffect(() => {
    populateMarkersOnMap(filteredRestaurants);
  }, [filteredRestaurants]);


  const getNearByRestaurants = (lat: number, long: number) => {
    var today = new Date();
    const dateFrom =
      today.getFullYear() * 1e4 +
      (today.getMonth() + 1) * 100 +
      today.getDate() +
      '';
    const lastWeekDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 6,
    );
    const dateTo =
      lastWeekDate.getFullYear() * 1e4 +
      (lastWeekDate.getMonth() + 1) * 100 +
      lastWeekDate.getDate() +
      '';
    dispatch(getNearByResturantListRequest(lat, long, 40, 6, dateFrom, dateTo));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const getLocationError = (error: any) => {
    let errorMsg = '';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMsg = 'User denied the request for Geolocation.';
        break;
      case error.POSITION_UNAVAILABLE:
        errorMsg = 'Location information is unavailable.';
        break;
      case error.TIMEOUT:
        errorMsg = 'The request to get user location timed out.';
        break;
      case error.UNKNOWN_ERROR:
        errorMsg = 'An unknown error occurred.';
        break;
      default:
        errorMsg = 'An unknown error occurred.';
    }

    return errorMsg;
  };
  useEffect(() => {
    setFilteredRestaurants(nearbyRestaurants);
  }, [nearbyRestaurants]);
  const currentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat: any = parseFloat(String(position.coords.latitude));
          const lng: any = parseFloat(String(position.coords.longitude));

          getGeoCode({
            location: {
              lat: Number(lat),
              lng: Number(lng),
            },
          })
            .then(async (results: any) => {
              const address = getAddress(results[0]);

              if (address.address1 !== '') {
                getNearByRestaurants(lat, lng);
                handleClickOpen();
                setFilteredRestaurants(nearbyRestaurants?.restaurants);
                return;
              }
            })
            .catch((error) => {
              displayToast(
                'ERROR',
                'No address found against your current location.',
              );
            });

          setZoom(7);
        },
        function (error) {
          displayToast('ERROR', getLocationError(error));

          setZoom(7);
        },
      );
    }
  };
  useEffect(() => {
    if (matchedRestaurant) {
      setRestaurantInfo(matchedRestaurant);
      if (matchedRestaurant?.extref) {
        dispatch(getSingleLocation(matchedRestaurant?.extref));
        // setRunOnce(false);
      }
    }
  }, [matchedRestaurant?.extref]);
  useEffect(() => {
    if (restaurantInfo) {
      var today = new Date();
      const dateFrom =
        today.getFullYear() * 1e4 +
        (today.getMonth() + 1) * 100 +
        today.getDate() +
        '';
      const lastWeekDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 6,
      );
      const dateTo =
        lastWeekDate.getFullYear() * 1e4 +
        (lastWeekDate.getMonth() + 1) * 100 +
        lastWeekDate.getDate() +
        '';
      dispatch(
        getResturantCalendarRequest(restaurantInfo.id, dateFrom, dateTo),
      );
    }
  }, [restaurantInfo?.id]);
  useEffect(() => {
    dispatch(getResturantListRequest());
  }, []);
  function truncateText(text: any) {
    return text;
  }

  function makeAddressRest(matchedRestaurant: any, maxLength: any) {
    const address = `${matchedRestaurant?.streetaddress}, ${matchedRestaurant?.city}`;

    return truncateText(address);
  }
  function formatWeekdays(weekdays: any) {
    if (weekdays.length === 1) {
      return weekdays[0];
    } else {
      return `${weekdays[0]} - ${weekdays[weekdays.length - 1]}`;
    }
  }
  function formatRange(range: any) {
    const { start, end, weekdays } = range;
    const weekdayString = formatWeekdays(weekdays);
    const formattedStart = formatCalendarTime(start);
    const formattedEnd = formatCalendarTime(end);
    return `${weekdayString}: ${formattedStart} - ${formattedEnd} `;
  }
  function formatBusinessHours(hours: any) {
    let formattedHours = [];
    let currentRange = { start: '', end: '', weekdays: [] as string[] };

    for (let i = 0; i < hours.length; i++) {
      const { start, end, weekday } = hours[i];
      const splitStart = start.split(' ');
      const splitEnd = end.split(' ');
      const splitCurrRangeStart = currentRange.start?.split(' ');
      const splitCurrRangeEnd = currentRange?.end?.split(' ');
      if (
        splitStart[splitStart.length - 1] !==
          splitCurrRangeStart?.[splitCurrRangeStart.length - 1] ||
        splitEnd[splitEnd.length - 1] !==
          splitCurrRangeEnd?.[splitCurrRangeEnd.length - 1]
      ) {
        if (currentRange.weekdays.length > 0) {
          formattedHours.push(formatRange(currentRange));
          currentRange = { start, end, weekdays: [weekday] };
        } else {
          currentRange.start = start;
          currentRange.end = end;
          currentRange.weekdays.push(weekday);
        }
      } else {
        currentRange.weekdays.push(weekday);
      }
    }

    if (currentRange.weekdays.length > 0) {
      formattedHours.push(formatRange(currentRange));
    }

    return formattedHours;
  }

  const formatCalendarTime = (time: any) => {
    if (time) {
      return formatDateTime(time, 'h:mm A', 'YYYYMMDD HH:mm');
    }
  };
  const fitMapView = () => {
    const locationCardView = document.getElementById('location-card-view');
    const googleMapLocation = document.getElementById(
      'google-map-location-change',
    );
    const pageHeader: any = document.getElementById('page-header');

    if (googleMapLocation) {
      if (locationCardView) {
        googleMapLocation.style.height = locationCardView?.clientHeight + 'px';
      }
      if (pageHeader) {
        googleMapLocation.style.top = pageHeader?.clientHeight + 'px';
      }
    }
  };

  const formatDateTime = (date: any, outFormat: any, inputFormat: any) => {
    if (date && inputFormat) {
      return moment(date, inputFormat).format(outFormat);
    }
    return moment(date).format(outFormat);
  };
  const reArrangeCalenderRange = (calendars: any) => {
    const formattedRange: any[] = [];
    ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].forEach((day) => {
      calendars?.ranges?.forEach((range: any) => {
        if (range?.weekday?.toLowerCase() === day.toLowerCase()) {
          return formattedRange.push(range);
        }
      });
    });
    return formattedRange;
  };

  const getSearchResults = (type: any) => {
    setAction(actionTypes.LOCAL_SEARCH);
    let availableRestaurants = [];
    availableRestaurants = getOrderTypeRestaurants(restaurants, orderType);
    let searchedRestaurant: ResponseRestaurant[] = [];
    const searchTextTrim = searchText?.trim()?.toLowerCase() ?? '';
    if (searchTextTrim.length > 1 && type !== 'clear') {
      searchedRestaurant = availableRestaurants.filter((x: any) =>
        x.city.toLowerCase().includes(searchTextTrim),
      );
      if (searchedRestaurant.length === 0) {
        searchedRestaurant = availableRestaurants.filter(
          (x: any) =>
            x.state.includes(searchTextTrim) ||
            x.stateName?.toLowerCase()?.includes(searchTextTrim),
        );
      }
      if (searchedRestaurant.length === 0) {
        getGeoCode({ address: searchTextTrim })
          .then((results) => {
            getLatLng(results[0]).then(({ lat, lng }) => {
              getNearByRestaurants(lat, lng);
            });
          })
          .catch((error) => {
            setFilteredRestaurants([]);
            setRestaurantNotFound(true);
          });
      } else {
        setFilteredRestaurants(searchedRestaurant);
      }
    } else {
      setFilteredRestaurants(availableRestaurants);
    }
  };

  const { fullWeekCalendar, todayCalendar } = useMemo(() => {
    const businessCalendar =
      calendar?.calendar?.filter(
        (item: any) => item && item.type === 'business',
      )[0] || [];
    const todayCalendarStart = formatCalendarTime(
      businessCalendar?.ranges?.[0]?.start,
    );
    const todayCalendarEnd = formatCalendarTime(
      businessCalendar?.ranges?.[0]?.end,
    );
    const businessHours = reArrangeCalenderRange(businessCalendar);
    const formattedHours = formatBusinessHours(businessHours);

    return {
      todayCalendar: `Open Today: ${todayCalendarStart} - ${todayCalendarEnd}`,
      fullWeekCalendar: formattedHours,
    };
  }, [calendar]);
  const loadMap = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const handleChangeSearchText = (e: any) => {
    setSearchText(e?.target?.value);
  };
  return (
    <>
      {openDialog === true && (
        <MapDialog
          zoom={zoom}
          mapCenter={mapCenter}
          markers={markers}
          action={action}
          actionTypes={actionTypes}
          currentLocation={currentLocation}
          markerRef={markerRef}
          filteredRestaurants={filteredRestaurants}
          open={openDialog}
          onClose={handleCloseDialog}
          onLoad={loadMap}
          fitMapView={fitMapView}
          searchText={searchText}
          getSearchResults={getSearchResults}
          setSearchText={setSearchText}
          handleChangeSearchText={handleChangeSearchText}
          setOpenDialog={setOpenDialog}
          newFavLocation={newFavLocation}
          setNewFavLocation={setNewFavLocation}
        />
      )}
      <Page title={'Order History'} className="">
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          container
          className={classes.root}
        >
          <Grid item xs={12}>
            <Typography
              className={classes.heading}
              aria-label="your orders"
              variant="h1"
              title="your orders"
              tabIndex={0} role='heading'
              sx={{ fontFamily: "'GritSans-Bold' !important", fontSize: '30px !important', paddingBottom: '15px', color: '#224C65 !important' }}
            >
              FAVORITE RESTAURANT
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            role="group"
            aria-label={'Restaurant'}
            sx={{
              display: 'flex',
              cursor: 'pointer',
              height: showMore ? '193px' : '160px',
              padding: { xs: '0px', sm: '10px' },
              borderRadius: '16px !important',
              boxShadow: '0px 0px 13px 0px rgba(161, 161, 161, 0.30)',
              width: { sm: '350px', xs: 'auto' },
              maxWidth: { sm: '350px', xs: 'auto' },
              justifyContent: 'center',
              marginTop: '20px',
            }}
            key={matchedRestaurant?.id}
          >
            <Grid
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '5px',
                  padding: '10px',
                }}
              >
                <div style={{ alignSelf: 'center' }}>
                  <img src={PickUpAddress} />
                </div>
                <Grid
                  xs={7}
                  sm={12}
                  sx={{
                    marginLeft: '15px',
                    color: '#000',
                    alignSelf: 'center',
                    textDecoration: 'none',
                  }}
                >
                  {restaurantLoading ? (
                    <Skeleton
                      sx={{
                        fontWeight: 'bold',
                        height: '30px',
                        fontSize: '14px',
                        color: '#052C42',
                        marginRight: '20px',
                        fontFamily: 'Librefranklin-Bold !important',
                      }}
                    />
                  ) : (
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                        color: '#052C42',
                        textTransform: 'Uppercase !important',
                        fontFamily: 'Librefranklin-Bold !important',
                      }}
                    >
                      {removeNumberFromRestaurantName(matchedRestaurant?.name)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Divider
                sx={{
                  margin: { sm: '2px 1px 0px -10px', xs: '2px 1px 0px 0px' },
                  width: { sm: '350px', xs: '100%' },
                }}
              />
              <Grid sx={{ padding: { sm: '10px', xs: '10px 16px 21px 16px' } }}>
                {restaurantLoading ? (
                  <Skeleton
                    sx={{
                      alignSelf: 'center',
                      margin: '16px',
                      color: '#3D50DF',
                      textDecoration: 'underline',
                    }}
                  />
                ) : (
                  <>
                    <Grid xs={12} sx={{ display: 'flex' }}>
                      <Grid xs={9}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Librefranklin-Regular',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            letterSpacing: '0.15px',
                            margin: '8px 0px 0px 0px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {makeAddressRest(matchedRestaurant, 26)}
                        </Typography>
                      </Grid>
                      <Grid xs={3}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Librefranklin-Regular',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            letterSpacing: '0.15px',
                            color: '#052C4280',
                            textAlign: 'end',
                            margin: '8px 0px 0px 0px',
                          }}
                        >
                          {favDistance > 0 && favDistance + ' mi'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '8px',
                      }}
                    >
                      {todayCalendar.includes('undefined') ? (
                        <Grid xs={8} sx={{ marginTop: '-6px !important' }}>
                          <Skeleton
                            sx={{
                              height: '40px',
                              alignSelf: 'center',
                              color: '#3D50DF',
                              textDecoration: 'underline',
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          xs={8}
                          sx={{
                            maxWidth: !showMore
                              ? '183px !important'
                              : '200px !important',
                            width: '183px',
                            color: '#052C4280',
                            fontSize: '12px !important',
                            fontFamily: "'Librefranklin-Regular' !important",
                          }}
                        >
                          {todayCalendar.includes('undefined')
                            ? ''
                            : showMore && todayCalendar}
                          {fullWeekCalendar.includes('undefined')
                            ? ''
                            : !showMore && fullWeekCalendar}
                        </Grid>
                      )}
                      <Grid
                        xs={4.2}
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                        sx={{
                          marginLeft: 'auto',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Librefranklin-Regular',
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '18px',
                            letterSpacing: '0.15px',
                            color: '#3D50DF',
                            textDecoration: 'underline',
                          }}
                        >
                          {showMore ? 'More Hours' : "Today's Hours"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid >
          <Grid sx={{
            float: 'right',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            color: '#006DB7',
            width: { sm: '350px', xs: 'auto' },
            fontFamily: 'Librefranklin-Regular',
            textDecoration: 'underline',
            marginTop: '15px',
            cursor: 'pointer',
            marginLeft: { xs: '-20px', sm: '0px' }
          }}
            tabIndex={0}
            onClick={() => handleClickOpen()}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleClickOpen()
              }
            }}
          >
            Change Favorite Restaurant
            <img
              src={changeStoreIcon}
              style={{ marginLeft: '8px', color: '#006DB7' }}
            />
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default React.memo(FavouriteRestaurant);
