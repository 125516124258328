export enum restaurantActionsTypes {
  GET_RESTAURANT_INFO_REQUEST = 'GET_RESTAURANT_INFO_REQUEST',
  GET_RESTAURANT_INFO_SUCCESS = 'GET_RESTAURANT_INFO_SUCCESS',
  GET_RESTAURANT_INFO_FAILURE = 'GET_RESTAURANT_INFO_FAILURE',

  SET_RESTAURANT_INFO_REQUEST = 'SET_RESTAURANT_INFO_REQUEST',
  SET_RESTAURANT_INFO_SUCCESS = 'SET_RESTAURANT_INFO_SUCCESS',
  SET_RESTAURANT_INFO_FAILURE = 'SET_RESTAURANT_INFO_FAILURE',

  SET_RESTAURANT_INFO_ORDER_TYPE = 'SET_RESTAURANT_INFO_ORDER_TYPE',
  RESTAURANT_RESET_REQUEST = 'RESTAURANT_RESET_REQUEST',
  SET_RESTAURANT_SESSION_REQUEST = 'SET_RESTAURANT_SESSION_REQUEST',

  SET_MENU_MOBILE = 'SET_MENU_MOBILE',

  SAVE_DELIVERY_ADDRESS = 'SAVE_DELIVERY_ADDRESS',

  GET_SELECTED_RESTAURANT_FOR_MOBILE = 'GET_SELECTED_RESTAURANT_FOR_MOBILE',
  SAVE_LINK_ADDRESS = 'SAVE_LINK_ADDRESS',
  SAVE_HANDOFF_MODE = 'SAVE_HANDOFF_MODE',
  FAVORITE_LOCATION = 'FAVORITE_LOCATION',

  SET_CUSTOMER_MESSAGE_STATUS = 'SET_CUSTOMER_MESSAGE_STATUS',
}
