import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppRoutes from './routes';

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/footer';
import Header from './components/header';
import LeftMenuBar from './components/left-menu-bar';
import NavigateApp from './components/navigate-app';
import { updateDeviceUniqueId } from './redux/actions/auth';
import { resetBasketRequest } from './redux/actions/basket';
import { updateDuplicateAddress } from './redux/actions/basket/checkout';
import {
  resetRestaurantRequest,
  updateRestaurantSessionRequest,
} from './redux/actions/restaurant';

import { isLoginUser } from './helpers/auth';
import { removePreviousAddresses } from './helpers/checkout';
import { generateDeviceId } from './helpers/common';
import { getUserprofile } from './redux/actions/user';

import moment from 'moment';
import TagManager from 'react-gtm-module';

import 'react-toastify/dist/ReactToastify.css';
import './one-trust.css';
import { setPageStateRequest } from './redux/actions/page-state';

const { REACT_APP_RESTAURANT_SESSION_TIME } = process.env;

function App() {
  const windowsPathName = window.location.href.toLocaleLowerCase();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isAccountSection, setIsAccountSection] = useState(false);
  const [hideLoginPanel, setHideLoginPanel] = useState(true);
  const [hideLoginedPanel, setHideLoginedPanel] = useState(false);
  const dispatch = useDispatch();
  const { deviceId } = useSelector((state: any) => state.authReducer);
  const { basket } = useSelector((state: any) => state.basketReducer);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const path = window.location.href.toLocaleLowerCase();
  const { addresses: basketAddresses } = useSelector(
    (state: any) => state.basketReducer,
  );
  const { address: deliveryAddress } = useSelector(
    (state: any) => state.deliveryAddressReducer,
  );
  const { restaurant, sessionTime: restaurantSessionTime } = useSelector(
    (state: any) => state.restaurantInfoReducer,
  );
  const { userProfile } = useSelector((state: any) => state.userReducer);
  const favouriteStoreNumber = userProfile?.favourite_store_numbers;
  const { restaurants } = useSelector(
    (state: any) => state.restaurantListReducer,
  );

  useEffect(() => {
    dispatch(getUserprofile());
  }, []);
  const productCount = useMemo(() => basket?.products?.length || 0, [basket]);

  const shouldHideHeader =
    isMobile && location.pathname.toLowerCase().indexOf('/checkout') !== -1;

  useEffect(() => {
    if (basketAddresses?.duplicated?.length) {
      removePreviousAddresses(basketAddresses, deliveryAddress, basket, false);
    }
  }, [basket]);
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          // Check if the added nodes include a toast element
          const toastElement = document.querySelector('.Toastify__toast') as HTMLElement;
          if (toastElement) {
            toastElement.setAttribute('tabIndex', '-1'); // Ensure it is focusable without changing tab order
            toastElement.focus(); // Focus on the toast
            // Ensure the close button is focusable
            const closeButton = toastElement.querySelector('.Toastify__close-button') as HTMLElement;
            const svgElement = toastElement.querySelector('svg');
  
            if (closeButton && svgElement) {
              closeButton.setAttribute('tabIndex', '0'); // Make the close button keyboard accessible
  
              // Add focus event to close button to apply outline to the SVG
              closeButton.addEventListener('focus', () => {
                svgElement.style.outline = '2px solid #71ffff'; // Apply outline to SVG when close button is focused
              });
  
              // Remove outline when close button loses focus
              closeButton.addEventListener('blur', () => {
                svgElement.style.outline = 'none'; // Remove outline from SVG when close button loses focus
              });
            }
  
            // Optionally, keep the focus within the toast while it's visible (useful for accessibility)
            const handleFocusIn = (e: FocusEvent) => {
              if (toastElement && !toastElement.contains(e.target as Node)) {
                toastElement.focus(); // Bring focus back to the toast if focus moves outside
              }
            };
  
            document.addEventListener('focusin', handleFocusIn);
  
            // Clean up the event listener when the toast is removed
            const toastRemovedObserver = new MutationObserver(() => {
              if (!document.contains(toastElement)) {
                document.removeEventListener('focusin', handleFocusIn);
                toastRemovedObserver.disconnect(); // Stop observing once the toast is gone
              }
            });
  
            // Observe if the toast is removed (e.g., closed by the user)
            toastRemovedObserver.observe(document.body, {
              childList: true,
              subtree: true,
            });
          }
        }
      }
    });
  
    // Observe the toast container for any added nodes (toast messages)
    const toastContainer = document.querySelector('.Toastify');
    if (toastContainer) {
      observer.observe(toastContainer, { childList: true });
    }
  
    // Cleanup observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  

  useEffect(() => {
    if (
      basketAddresses?.duplicated &&
      Array.isArray(basketAddresses?.duplicated) &&
      basket?.deliveryaddress?.id &&
      !basketAddresses?.duplicated.includes(basket?.deliveryaddress?.id) &&
      deliveryAddress?.id !== basket?.deliveryaddress?.id
    ) {
      const updatedDuplicateAddress = [
        ...basketAddresses?.duplicated,
        basket.deliveryaddress.id,
      ];
      dispatch(updateDuplicateAddress(updatedDuplicateAddress));
    }
  }, [basket]);

  const updateDeviceId = () => {
    const newDeviceId = generateDeviceId();
    if (newDeviceId) {
      dispatch(updateDeviceUniqueId(newDeviceId));
    }
  };

  useEffect(() => {
    if (!deviceId) {
      updateDeviceId();
    } else {
      const splitArray = deviceId.split('_');
      if (splitArray.length === 2) {
        const deviceIdTime: any = moment.unix(splitArray[1]);
        const currentTime = moment();
        if (deviceIdTime.isValid()) {
          const days = currentTime.diff(deviceIdTime, 'days');
          if (days > 7) {
            updateDeviceId();
          }
        } else {
          updateDeviceId();
        }
      } else {
        updateDeviceId();
      }
    }
  }, []);

  let intervalId: any;

  useEffect(() => {
    if (restaurant && !restaurantSessionTime) {
      const currentTime = moment().unix();
      dispatch(updateRestaurantSessionRequest(currentTime));
    }
  }, []);

  const clearOrderCacheAfterTimeout = () => {
    const timeLimit: number = REACT_APP_RESTAURANT_SESSION_TIME
      ? parseInt(REACT_APP_RESTAURANT_SESSION_TIME)
      : 0;
    if (restaurant && restaurantSessionTime) {
      const restaurantSessionTimeMoment: any = moment.unix(
        restaurantSessionTime,
      );
      const currentTime = moment();
      if (restaurantSessionTimeMoment.isValid()) {
        const minutes = currentTime.diff(
          restaurantSessionTimeMoment,
          'minutes',
        );
        if (minutes > timeLimit) {
          removePreviousAddresses(
            basketAddresses,
            deliveryAddress,
            basket,
            true,
          );
          dispatch(resetRestaurantRequest());
          dispatch(resetBasketRequest());
          navigate('/location');
          dispatch(updateRestaurantSessionRequest(null));
        }
      }
    }
  };
  useEffect(() => {
    clearOrderCacheAfterTimeout();
    intervalId = setInterval(function () {
      clearOrderCacheAfterTimeout();
      localStorage.setItem('tipPercentage', '');
    }, 30 * 1000);
    return () => clearInterval(intervalId);
  }, [window.location.href]);

  useEffect(() => {
    const tagManagerArgs: any = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    if (path.includes('/account')) {
      if (!isLoginUser()) {
        navigate('/login');
      }
    } else {
      setHideLoginedPanel(false);
    }
    if (
      path.includes('/menu') ||
      path.includes('/location') ||
      path.includes('/checkout') ||
      path.includes('/category') ||
      path.includes('/product') ||
      path.includes('/reset-password') ||
      path.includes('/forgot-password')
    ) {
      setHideLoginPanel(false);
    } else {
      setHideLoginPanel(true);
    }
    if (path.includes('/account')) {
      setHideLoginedPanel(true);
    } else {
      setHideLoginedPanel(false);
    }
  }, [window.location.href]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (path.includes('/account')) {
      setIsAccountSection(true);
      setHideLoginedPanel(true);
      if (!isLoginUser()) {
        navigate('/login');
      }
    } else {
      setIsAccountSection(false);
      setHideLoginedPanel(false);
    }
    if (path.includes('/menu') || path.includes('/checkout')) {
      setHideLoginPanel(false);
    } else {
      setHideLoginPanel(true);
    }
    if (path.includes('/account')) {
      setHideLoginedPanel(true);
    } else {
      setHideLoginedPanel(false);
    }
    if (path.includes('/login') || path.includes('/register')) {
    } else {
      dispatch(
        setPageStateRequest(window.location.href.replace(/.*\/\/[^\/]*/, '')),
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      if (basket) {
      } else if (isLoginUser()) {
        navigate('/welcome');
      }
    }
  }, []);

  return (
    <div>
      <div id="wapper">
        <NavigateApp />
        <a href="#main" className="skip-link">
          Skip to Main Content
        </a>
        {!shouldHideHeader && (
          <>
            <Header
              style={{ margin: '0 !important', padding: '0 !important' }}
              removeCartForLocation={path.includes('/location')}
              hideLoginPanel={hideLoginPanel}
              hideLoginedPanel={hideLoginedPanel}
              showUserName={isAccountSection}
              removeCart={
                isAccountSection ||
                path.includes('/checkout') ||
                path.includes('/welcome') ||
                path.includes('/order-confirmation')
              }
            />
          </>
        )}
        <Box component="main" id={'main'}>
          <ToastContainer hideProgressBar />
          {isAccountSection ? (
            <Fragment>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={0}
                  sm={3.5}
                  lg={2.5}
                  sx={{ display: { xs: 'none', sm: 'grid' } }}
                >
                  <LeftMenuBar />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8.5}
                  lg={9}
                  sx={{ padding: { xs: '30px 20px 10px', sm: '30px 40px' } }}
                >
                  <AppRoutes />
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <AppRoutes />
          )}
        </Box>
        <Footer />
      </div>
    </div>
  );
}
export default App;
